import LBAExpansionPanel from '@/components/ui/expansion/LBAExpansionPanel.vue';
import LBAButton from '@/components/ui/buttons/LBAButton.vue';
export default {
  components: {
    LBAExpansionPanel,
    LBAButton
  },
  methods: {
    cleared() {
      this.$emit('clear');
    },
    clicked() {
      this.$emit('click');
    }
  }
};