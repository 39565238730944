import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('LBAExpansionPanel', {
    attrs: {
      "textColor": 'default',
      "titleType": 'default'
    },
    on: {
      "click": _vm.clicked
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_c(VRow, [_c(VIcon, {
          staticClass: "mr-3"
        }, [_vm._v("mdi-filter")]), _c('span', {
          staticClass: "mt-1"
        }, [_vm._v("Filter")])], 1)];
      },
      proxy: true
    }, {
      key: "content",
      fn: function () {
        return [_vm._t("content")];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function () {
        return [_c('LBAButton', {
          attrs: {
            "buttonType": 'error',
            "buttonStyle": 'text'
          },
          on: {
            "click": _vm.cleared
          },
          scopedSlots: _vm._u([{
            key: "content",
            fn: function () {
              return [_vm._v("Filter Löschen")];
            },
            proxy: true
          }])
        })];
      },
      proxy: true
    }], null, true)
  });
};
var staticRenderFns = [];
export { render, staticRenderFns };