import axios from 'axios'

export default {
  async getDocumentById(wf_cid) {
    return axios.get(`/v1/crm/documents/${wf_cid}`)
  },

  printDocument(item) {
    item.printLoading = true

    return axios
      .get(`/v1/crm/documents/${item.wf_cid}/pdf`, {
        responseType: 'blob'
      })
      .then(response => {
        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: 'application/pdf' })
        )

        // const pdfDownloadPopUp = window.open(url, '_blank');
        // if(pdfDownloadPopUp) {
        //   pdfDownloadPopUp.focus();
        // }
        // // Fallback for when popup isn't allowed
        // else {
        const pdfLink = document.createElement('a')
        pdfLink.href = url
        pdfLink.download = item.name + '.pdf'
        pdfLink.target = '_blank'
        document.body.appendChild(pdfLink)
        pdfLink.click()
        document.body.removeChild(pdfLink)
        // }

        item.printLoading = false
      })
  },
  async getAvailableUpgrades(params) {
    return axios.get(`/v1/offer/${params.wf_cid}/availableUpgrades`)
  }
}
